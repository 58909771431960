import { defineStore } from "pinia";

export const useCommonStore =
  defineStore("commonStore", () => {
    const runtimeConfig = useRuntimeConfig();
    const phoneNumberPrefix = ref<string>("");

    const setPhoneNumberPrefix = (prefix : string) => {
      phoneNumberPrefix.value = prefix;
    };

    const selectedCountry = computed<Country>(() => {
      const identifier = phoneNumberPrefix.value.length > 0 ? phoneNumberPrefix.value.replace("+", "") : runtimeConfig.public.region.toUpperCase();
      const country = COUNTRIES.find(
        country => country.dialCode === identifier || country.iso2 === identifier
      );

      return country || COUNTRIES[0];
    });

    const COUNTRIES: Country[] = [
      {
        name: "Česká republika",
        iso2: "CZ",
        dialCode: "420",
        priority: 0,
        areaCodes: null
      },
      {
        name: "Slovensko",
        iso2: "SK",
        dialCode: "421",
        priority: 1,
        areaCodes: null
      },
      {
        name: "Ukraine",
        iso2: "UA",
        dialCode: "380",
        priority: 2,
        areaCodes: null
      },
      {
        name: "Polska",
        iso2: "PL",
        dialCode: "48",
        priority: 3,
        areaCodes: null
      },
      {
        name: "Deutschland",
        iso2: "DE",
        dialCode: "49",
        priority: 4,
        areaCodes: null
      },
      {
        name: "Österreich",
        iso2: "AT",
        dialCode: "43",
        priority: 5,
        areaCodes: null
      }
    ];

    return {
      phoneNumberPrefix,
      selectedCountry,
      setPhoneNumberPrefix
    };
  });
